<template>
  <div class="bg-primary">
    <div class="container custom-container vw-100">
      <div class="row">
        <div class="col p-3 text-center text-white">
          <img :src="require('@/assets/img/logo.png')" width="100px" alt="" srcset="" />
          <p style="margin-bottom: 0%">Federal Ministry of</p>
          <h3 style="margin-bottom: 0%">HEALTH</h3>
        </div>
      </div>

      <div
        v-if="stage == REQUEST_RESET"
        autocomplete="off"
        class="row justify-content-center align-items-center"
      >
        <form class="bg-white col-md-7 p-5 rounded" @submit.stop.prevent="handleReset">
          <div class="row">
            <div class="col text-center mb-4">
              <h4 class="h4 font-weight-bold text-muted">Did you forget your password?</h4>
              <p class="text-muted">Please enter your email address to retrieve your password</p>
            </div>
          </div>

          <div class="px-3 mb-3">
            <b-form-group
              label="Email address"
              label-for="email"
              description="Please provide your MDT email address"
              class="text-dark font-weight-bold"
            >
              <b-form-input
                id="email"
                v-model="resetForm.email"
                v-validate="'required|email'"
                name="email"
                type="email"
                placeholder="example@mail.net"
                class="form-control-lg"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first("email") }}</b-form-invalid-feedback>
            </b-form-group>

            <button
              type="submit"
              class="btn btn-primary btn-block btn-lg shadow"
              @click.prevent="requestPasswordReset"
            >
              Retrieve password
            </button>

            <div class="mt-3 text-center">
              <span class="text-muted"
                >Your password will be sent to your email address. Please check your mail</span
              >
            </div>

            <div class="form-group">
              <span class="text-muted">
                To Login
                <AppLink to="/login">
                  <a href="#" class="font-weight-bold text-info">Click here</a>
                </AppLink>
              </span>
            </div>
          </div>
        </form>
      </div>

      <div
        v-if="stage == CHANGE_PASSWORD"
        autocomplete="off"
        class="row justify-content-center align-items-center"
      >
        <form class="bg-white col-md-7 p-5 rounded" @submit.stop.prevent="handleReset">
          <div class="row">
            <div class="col text-center mb-4">
              <h4 class="h4 font-weight-bold text-muted">Password Reset</h4>
              <p class="text-muted">
                Please enter the activation code sent to {{ resetForm.email }}
              </p>
            </div>
          </div>

          <div class="px-3 mb-3">
            <b-form-group
              label="Activation code"
              label-for="code"
              description=""
              class="text-dark font-weight-bold"
            >
              <b-form-input
                id="code"
                v-model="activationForm.code"
                v-validate="'required|length:40'"
                autocomplete="off"
                name="code"
                data-vv-as="Activation code"
                type="text"
                class="form-control-lg"
                :state="validateState('code')"
              ></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first("code") }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="New Password"
              label-for="password"
              class="text-dark font-weight-bold"
            >
              <b-form-input
                ref="password"
                v-model="activationForm.password"
                v-validate="'required|min:6'"
                autocomplete="off"
                data-vv-as="New Password"
                name="password"
                type="password"
                class="form-control-lg"
                :state="validateState('password')"
              ></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first("password") }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Confirm Password"
              label-for="confirmPassword"
              class="text-dark font-weight-bold"
            >
              <b-form-input
                v-model="resetForm.password"
                v-validate="'required|confirmed:password'"
                autocomplete="off"
                name="confirm_password"
                type="password"
                data-vv-as="confirm password"
                class="form-control-lg"
                :state="validateState('confirm_password')"
              ></b-form-input>
              <b-form-invalid-feedback>{{
                veeErrors.first("confirm_password")
              }}</b-form-invalid-feedback>
            </b-form-group>

            <button
              type="submit"
              class="btn btn-primary btn-block btn-lg shadow"
              @click.prevent="submitPasswordReset"
            >
              Change Password
            </button>
          </div>
          <div class="form-group">
            <span class="text-muted">
              <a
                href="#"
                class="font-weight-bold text-info"
                @click.prevent="setStage(REQUEST_RESET)"
                >Resend Request</a
              >
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { validEmail } from "../../utils/validate";
import { forgotPassword, resetPassword } from "../../api/auth";
import AppLink from "../../components/layout/components/Sidebar/Link.vue";

const REQUEST_RESET = 0;
const CHANGE_PASSWORD = 1;
const ACTIVATION_FORM = {
  code: null,
  password: null,
  confirmPassword: null,
};
export default {
  components: {
    AppLink,
  },
  data() {
    return {
      REQUEST_RESET,
      CHANGE_PASSWORD,
      stage: CHANGE_PASSWORD,
      resetForm: {
        email: null,
      },
      activationForm: ACTIVATION_FORM,
    };
  },
  computed: {
    validateUsernameEmail() {
      return this.resetForm.email && validEmail(this.resetForm.email);
    },
    validateUsernameEmailMsg() {
      if (!this.validateUsernameEmail) return "Please provide a valid Email Adress";
      return "";
    },
  },
  methods: {
    /**
     *
     */
    setStage(stage) {
      this.stage = stage;
    },
    /**
     *
     */
    handleReset() {
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    /**
     *
     */
    requestPasswordReset() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.$progress.start();
        forgotPassword(this.resetForm.email)
          .then((response) => {
            this.$message({ message: response.message, type: "success", showClose: true });
            this.$nextTick(() => {
              this.handleReset();
              this.$progress.done();
              this.setStage(CHANGE_PASSWORD);
            });
          })
          .catch((errpr) => {
            console.error(errpr);
            this.$nextTick(() => {
              this.$progress.done();
              this.resetForm.email = null;
              this.setStage(REQUEST_RESET);
            });
          });
      });
    },

    /**
     *
     */
    submitPasswordReset() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.$progress.start();
        resetPassword({ token: this.activationForm.code, password: this.activationForm.password })
          .then((response) => {
            this.$message({ message: response.message, type: "success", showClose: true });
            this.$nextTick(() => {
              this.$progress.done();
              this.activationForm = ACTIVATION_FORM;
              this.setStage(REQUEST_RESET);
              this.$router.push(
                { path: "/login" },
                () => {},
                (error) => {
                  console.log(error);
                }
              );
            });
          })
          .catch((errpr) => {
            console.error(errpr);
            this.$nextTick(() => {
              this.$progress.done();
              this.activationForm = ACTIVATION_FORM;
              this.setStage(REQUEST_RESET);
            });
          });
      });
    },

    /**
     *
     */
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  height: 820px;
}
</style>
